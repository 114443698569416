import React, { useState , useEffect} from 'react';
import axios from 'axios';
const BASE_URL = 'https://akshay.teskt.com/api/index.php/';

function Results() {

    useEffect(()=>{
        fetchResults();
    },[]);


    const [items , setItems] = useState([]);

    const fetchResults = async () => {
        axios.get(`${BASE_URL}results`)
          .then(res => { 
           setItems(res.data.data);
        })


    }

    return(
        <section className="text-gray-700 body-font">
            <div className="container px-5 py-24 mx-auto" bis_skin_checked="1">
                <div className="flex flex-col text-center w-full mb-20" bis_skin_checked="1">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Top Results</h1>
                
                </div>
                <div className="flex flex-wrap -m-2" bis_skin_checked="1">
                { items.map(item => (
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full" bis_skin_checked="1">
                        <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg" bis_skin_checked="1">
                        <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/80x80"/>
                        <div className="flex-grow" bis_skin_checked="1">
                            <h1 className="text-gray-900 title-font font-medium">{item.name}</h1>
                            <h2 className="text-gray-500">{item.balance}</h2>
                            <p className="text-gray-500">{item.updated_time}</p>
                        </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </section>
    );
    
}

export default Results;

