import {BrowserRouter as Router , Route } from "react-router-dom";
import Header from './components/layout/Header';
import Main from './components/layout/Main';
import Footer from './components/layout/Footer';
import './App.css';
import Results from "./components/layout/Results";



function App() {
  
  return (
    <Router>
    <div className="App">
      <Header />
      {/* <Main/> */}
      <Route path="/" exact  component={Main}></Route>
      <Route path="/results" component={Results}></Route>
      <Footer/>
    </div>
    </Router>
  );
 
}

export default App;
