import React from 'react';
import WheelComponent from 'react-wheel-of-prizes';
import 'react-wheel-of-prizes/dist/index.css';
import axios from 'axios';
const BASE_URL = 'https://akshay.teskt.com/api/index.php/';

class Spinwheel extends React.Component {
  state = {
    stake_amount:'',
    choose_odd:'',
    balance: 1000,
    spin_value:'',
    win_balance: '',
    win_lose_status : '',
    player_id : '',
  }

  handleChange = event => {
    this.setState({
       [event.target.name]: event.target.value
      });
  }

  handleSubmit = event => {
    event.preventDefault();

    console.log( this.state);
    
    // console.log(user_data);
    // axios.post(`https://akshaypatil.orkhle.com/projects/api/api.php`,this.state)
    //   .then(res => {
    //     console.log(res);
    //     console.log(res.data);
    //     //this.setState({balance = res.data});
    //   })
    //this.state.onFinished;
  }

  componentDidMount() {
    this.initializePlayer();
  }

  initializePlayer (){
    const player_name = `player_${Date.now()}`;
    const player_email = `${player_name}@xyz.com`; 
    const player_password = 123456;
    const player_data = {
      'name' : player_name,
      'email' : player_email,
      'password' : player_password
    }
    // console.log(player_data);
      axios.post(`${BASE_URL}players/`,player_data,{ crossdomain: true })
        .then(res => {
          console.log(res.data.data);
          this.setState({ player_id: res.data});
        })
  }
  
  render() {
    const segments = [
      'better luck next time',
      'x2',
      'x10',
      'better luck next time',
      'x5',
      'better luck next time',
      'x3',
      'x10',
      'better luck next time',
      'x2',
      'x5',
      'better luck next time',
      'x3'
    ]
    const segColors = [
      '#EE4040',
      '#F0CF50',
      '#815CD1',
      '#3DA5E0',
      '#34A24F',
      '#F9AA1F',
      '#EC3F3F',
      '#FF9000',
      '#F0CF50',
      '#815CD1',
      '#3DA5E0',
      '#34A24F',
      '#F9AA1F'
    ];
    const onFinished = (winner) => {
        //console.log(winner);
        if(this.state.choose_odd !== '' && this.state.stake_amount !== ''){
        var spin_status = winner.replace('x','');
        const post_data = {
          'player_id' : this.state.player_id,
          'bet_option' : this.state.choose_odd,
          'bet_amount' : this.state.stake_amount,
          'status' : spin_status === this.state.choose_odd ? 1 : 0
        }
        console.log(`Status => ${spin_status} Odds=> ${this.state.choose_odd}`)

        console.log(post_data)
    
        axios.post(`${BASE_URL}playerstatus`,{ crossdomain: true },post_data)
            .then(res => {
              console.log(res);
              console.log(res.data.data);
              this.setState({ balance: res.data.data.balance});
              this.setState({ win_lose_status: res.data.status});
            })

        this.setState({ win_lose_status: spin_status === this.state.choose_odd ? 'You Win' : 'You Lose'});    
        }    
    }
    return (
   
      <div className="container  mx-auto flex sm:flex-no-wrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2  rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                <div className="bg-white relative flex flex-wrap py-6">
                  <WheelComponent
                  segments = {segments}
                  segColors = {segColors}
                  // winningSegment ={segments[2]}
                  onFinished={(winner)=>onFinished(winner)}
                  primaryColor='black'
                  contrastColor='white'
                  buttonText='Spin'/>

              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <form onSubmit={this.handleSubmit}>  
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Choose You Odds</h2>
            <p className="leading-relaxed mb-5 text-gray-600"></p>
            <div className="relative mb-4">
            <div className="flex items-center mr-4 mb-4">
                <div className="theme-space">
                  <input id="radio1" type="radio" name="choose_odd" className="hidden" value="2"  onChange={this.handleChange}/>
                  <label for="radio1" className="flex items-center cursor-pointer text-xl">
                  <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                  x2</label>
                </div>
                <div className="theme-space">
                  <input id="radio2" type="radio" name="choose_odd" className="hidden" value="3"  onChange={this.handleChange}/>
                  <label for="radio2" className="flex items-center cursor-pointer text-xl">
                  <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                  x3</label>
                </div>
                <div className="theme-space">
                  <input id="radio3" type="radio" name="choose_odd" className="hidden" value="5"  onChange={this.handleChange}/>
                  <label for="radio3" className="flex items-center cursor-pointer text-xl">
                  <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                  x5</label>
                </div>
                <div className="theme-space">
                  <input id="radio4" type="radio" name="choose_odd" className="hidden" value="10"  onChange={this.handleChange}/>
                  <label for="radio4" className="flex items-center cursor-pointer text-xl">
                  <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                  x10</label>
                </div>
            </div>
            </div>
            <div className="relative mb-4">
                <label for="name" className="leading-7 text-sm text-gray-600">Stake</label>
                <input type="number" max={100} pattern="[0-9]*" id="name" name="stake_amount" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" onChange={this.handleChange}/>
            </div>
            <div className="relative mb-4">
            <h3>{`Possible Win : ${this.state.choose_odd * this.state.stake_amount}`}</h3>  
            </div>
            <div className="relative mb-4">
            {/* <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg" >Click to Spin</button> */}
            </div>
            <div className="relative mb-4">
                <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">{this.state.win_lose_status}</h1>  
                <h3>Your Balance : {this.state.balance}</h3>  
            </div>
            </form>
            </div>
            
        </div>
      
     
    )
  }
}


export default Spinwheel;