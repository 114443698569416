import React from 'react'

import Spinwheel from './Spinwheel';

function Main() {


    return (
        <section className="text-gray-700 body-font relative">
            <div class="text-center" bis_skin_checked="1">
            <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">How to play</h1>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">1. Choice your winnings ODDS.</p>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">2. Choice your stake.</p>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">3. Click spin.</p>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">4. Win your prize instantly.</p>
            </div>
            <Spinwheel />
            
        </section>

    )
}


export default Main;